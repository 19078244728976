export default function (link, target = '_self') {
  if (link) {
    if (link.indexOf('gucci.com') > -1) {
      link += 'getGucciParams' in window ? `?${ window.getGucciParams() }` : ''
    }

    if (target === '_blank') {
      window.open(link, '_blank')
    } else {
      window.location.href = link
    }
  }
}
