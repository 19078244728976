import React from 'react'
import openLink from '~/helpers/openLink'

class Button extends React.Component {
  constructor () {
    super()

    this.onClick = this.onClick.bind(this)
  }

  onClick () {
    const { onClick, link, target } = this.props

    if (typeof onClick === 'function') {
      onClick()
    } else {
      openLink(link, target)
    }
  }

  render () {
    let { label, background, backgroundOpacity, fullWidth } = this.props

    backgroundOpacity = backgroundOpacity || 0.5 // default value

    return (
      <div
        className="wrapper"
        onClick={this.onClick}>
        <span className="label">{label}</span>

        <style jsx>{`
          .wrapper {
            width: ${ fullWidth ? '100%' : 'auto' };
            min-width: 176px;
            height: 38px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;
            font-family: 'Futura';
            font-size: 15px;
            font-weight: 300;
            text-align: center;
            color: #fff;
            background: ${ background
        ? `rgba(0,0,0,${ backgroundOpacity })`
        : 'none' };
            border: solid 1px white;
            cursor: pointer;
          }
          @media (min-width: 600px) {
            .wrapper {
              min-width: 210px;
              height: 50px;
              padding: 0 30px;
              font-size: 18px;
            }
          }
        `}</style>
      </div>
    )
  }
}

export default Button
