import React from 'react'
import BaseLayout from '~/layouts/base'
import Logo from '~/components/Logo'
import Button from '~/components/Button'
import desktop from '~/media/other/background-intro-desktop.jpg'
import mobile from '~/media/other/background-intro-mobile.jpg'
import openLink from '~/helpers/openLink'
import getQueryString from '~/helpers/getQueryString'

class IndexPage extends React.Component {
  state = {
    hasLogo: true
  }

  componentDidMount () {
    this.setLogo()
  }

  setLogo () {
    const queryString = getQueryString(window.location.href)

    const hasLogo = !(queryString.app === 'true')

    this.setState({ hasLogo })
  }

  onClick (id) {
    const link = `/${ id }/index.html${ window.location.search }`

    window.dataLayer.push({
      event: 'click_on_cta',
      eventCategory: 'interaction',
      eventAction: 'link click',
      eventLabel: `https://fall.gucci.com${ link }`,
    })

    openLink(link)
  }

  render () {
    const { hasLogo } = this.state

    return (
      <BaseLayout>
        <div className="wrapper">
          {hasLogo && <Logo />}

          <div className="button">
            <Button
              label="WOMAN COLLECTION"
              onClick={() => this.onClick(`woman`)}
              background={true}
              backgroundOpacity={0.8}
              fullWidth={true}
            />
          </div>
          <div className="button">
            <Button
              label="MAN COLLECTION"
              onClick={() => this.onClick(`man`)}
              background={true}
              fullWidth={true}
              backgroundOpacity={0.8}
            />
          </div>
        </div>

        <style jsx>{`
          .wrapper {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-image: url(${ mobile });
            background-position: center center;
            background-size: cover;
          }
          @media (min-width: 600px) {
            .wrapper {
              flex-direction: row;
              background-image: url(${ desktop });
            }
          }
          .button {
            width: 220px;
            margin: 20px 60px;
          }
          @media (min-width: 600px) {
            .button {
              width: 250px;
            }
          }
        `}</style>
      </BaseLayout>
    )
  }
}

export default IndexPage
