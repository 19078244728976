import EventEmitter from 'events'

EventEmitter.defaultMaxListeners = 1000

const defaultValues = {
  maxScroll: 1,
  value: 0,
  delta: 0,
  direction: 0,
}

const events = new EventEmitter()

class ScrollWithEase {
  constructor ({ deltaMultiplier, isActive }) {
    this.deltaMultiplier = deltaMultiplier || 1

    this.maxScroll = {
      application: 1,
      document: 1,
    }

    this.ticket = true
    this.isActive = isActive
    this.scroll = defaultValues

    window.addEventListener('scroll', () => this.onScroll())

    this.onScroll()
  }

  setMaxScroll ({ document, application }) {
    Object.assign(this.maxScroll, {
      document,
      application,
    })
  }

  active () {
    this.isActive = true

    this.reset(this.scroll.value)
  }

  disactive () {
    this.isActive = false
  }

  update () {
    if (this.isActive) {
      events.emit('scroll', this.scroll)
    }
  }

  set (data) {
    if (this.isActive) {
      Object.assign(this.scroll, data)

      this.update()
    }
  }

  onScroll () {
    if (!this.ticket) {
      return
    }

    this.ticket = false

    window.requestAnimationFrame(() => {
      this.ticket = true

      const { value: oldValue, direction: oldDirection } = this.scroll

      const progress = window.pageYOffset / this.maxScroll.document
      const current = progress * this.maxScroll.application
      const delta = Math.round((current - oldValue) * 1000) / 1000
      const value = oldValue + delta * this.deltaMultiplier
      const direction = delta ? (value > oldValue ? 1 : -1) : oldDirection

      this.set({
        maxScroll: this.maxScroll.application,
        delta,
        value,
        direction,
      })

      if (delta) {
        this.onScroll()
      }
    })
  }

  reset (value = 0) {
    const start = new Date()

    const cancel = () => {
      this._update = null
      window.scrollTo(0, value)
      window.removeEventListener('scroll', cancel)
    }

    cancel()

    this._update = () => {
      const now = new Date()

      this.set({
        delta: 0,
        value: value,
      })

      window.scrollTo(0, value)

      if (now - start < 300) {
        if (this._update) {
          window.requestAnimationFrame(this._update)
        }
      } else {
        cancel()
      }
    }

    window.addEventListener('scroll', cancel)

    this._update()
  }
}

let instance = false

export default {
  init: opts => {
    if (!instance) {
      instance = new ScrollWithEase(opts)
    }

    return instance
  },
  get: callback => {
    const values = instance ? instance.scroll : defaultValues

    typeof callback === 'function' && callback(values)

    return values
  },
  active: () => {
    if (instance) {
      return instance.active()
    }
  },
  disactive: () => {
    if (instance) {
      return instance.disactive()
    }
  },
  setMaxScroll: maxScroll => {
    if (instance) {
      return instance.setMaxScroll(maxScroll)
    }
  },
  reset: value => {
    if (instance && instance.isActive) {
      return instance.reset(value)
    }
  },
  on: (eventName, cb) => {
    events.on(eventName, cb)
  },
  off: (eventName, cb) => {
    events.off(eventName, cb)
  },
}
