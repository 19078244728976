import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'intersection-observer'

import 'normalize.css'
import '~/css/fonts.css'

const BaseLayout = ({ children, isScrollActive, body }) => {
  const { height } = body

  const isTouch =
    typeof document !== 'undefined' &&
    'ontouchstart' in document.documentElement

  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
              siteUrl
              shareImage
            }
          }
        }
      `}
      render={data => (
        <div className={isTouch ? 'touch' : 'no-touch'}>
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <meta
              name="description"
              content={data.site.siteMetadata.description}
            />
            <meta
              property="og:type"
              content="website" />
            <meta
              property="og:site_name"
              content="Gucci" />
            <meta
              property="og:url"
              content={data.site.siteMetadata.siteUrl} />
            <meta
              property="og:title"
              content={data.site.siteMetadata.title} />
            <meta
              property="og:description"
              content={data.site.siteMetadata.description}
            />
            <meta
              property="og:image"
              content={data.site.siteMetadata.shareImage}
            />
            <meta
              name="twitter:card"
              content="summary_large_image" />
            <meta
              name="twitter:title"
              content={data.site.siteMetadata.title} />
            <meta
              name="twitter:description"
              content={data.site.siteMetadata.description}
            />
            <meta
              name="twitter:image"
              content={data.site.siteMetadata.shareImage}
            />
          </Helmet>

          {children}

          <style
            jsx
            global>{`
            html {
              height: 100%;
              overflow-x: hidden;
              overflow-y: ${ isScrollActive ? 'scroll' : 'hidden' };
              overscroll-behavior: none;
            }
            body {
              height: ${ isScrollActive ? height : 0 }px;
              min-height: 100%;
              box-sizing: border-box;
              background: #11110f;
            }
            *,
            *:before,
            *:after {
              box-sizing: inherit;
              user-select: none;
              -webkit-font-smoothing: antialiased;
              -moz-font-smoothing: antialiased;
              -o-font-smoothing: antialiased;
              font-smoothing: antialiased;
              -webkit-tap-highlight-color: transparent;
            }
          `}</style>
        </div>
      )}
    />
  )
}

const mapStateToProps = ({ isScrollActive, body }) => ({
  isScrollActive,
  body,
})

export default connect(mapStateToProps)(BaseLayout)
