import React from 'react'
import logo from '~/media/icons/logo.svg'
import scrollWithEase from '~/helpers/scrollWithEase'
import { TweenMax } from 'gsap'

class Logo extends React.Component {
  state = {
    scroll: {
      value: 0,
    },
  }

  logo = React.createRef()

  constructor () {
    super()

    this.setScroll = this.setScroll.bind(this)
  }

  componentDidMount () {
    this.show()

    scrollWithEase.on('scroll', this.setScroll)
  }

  componentWillUnmount () {
    scrollWithEase.off('scroll', this.setScroll)
  }

  setScroll (scroll) {
    this.setState({ scroll })
  }

  show () {
    const { current: logo } = this.logo

    TweenMax.from(logo, 1, {
      yPercent: 100,
      delay: 1,
      ease: 'Circ.easeInOut',
    })
  }

  render () {
    const { scroll } = this.state

    return (
      <div className="wrapper">
        <img
          ref={this.logo}
          className="logo"
          src={logo}
          alt="" />

        <style jsx>{`
          .wrapper {
            z-index: 2;
            display: flex;
            position: fixed;
            width: 160px;
            top: 40px;
            left: 50%;
            transform: translate(-50%, ${ -scroll.value }px);
            overflow: hidden;
          }
          .logo {
            width: 100%;
          }
        `}</style>
      </div>
    )
  }
}

export default Logo
